import React from 'react'
import {Link} from 'gatsby'
import nokiaLogo from '../../assets/images/industries/nokia-logo.png'
import img1 from '../../assets/images/industries/img1.jpeg'
import img2 from '../../assets/images/industries/img2.jpeg'
import img3 from '../../assets/images/industries/img3.jpeg'
import img4 from '../../assets/images/industries/img4.jpeg'
import img5 from '../../assets/images/industries/img5.jpeg'

const IndustriesOne = () => {
    return (
        <section className="industries-area pt-md-70 pb-md-100">
            <div className="container">
                <div className="industries-start-content mb-5 px-md-3 mx-auto">
                    <div className="row mx-2 px-md-5 px-sm-3 py-5 img2">
                        <p className="col-sm-12 col-lg-8 text-lg-left text-center">It was so easy working with Inovett. After working with them, we started seeing results within weeks. It's awesome. </p>
                        <div className="col-sm-12 col-lg-4 px-lg-5">
                            <div className="row py-2 d-flex justify-content-around row1">
                                <img src={nokiaLogo} alt="partner" />
                            </div>
                            <div className="row d-flex justify-content-around mt-3 row2">
                                <p>TELECOMMUNICATIONS</p>
                            </div>
                        </div>
                    </div>
                </div>  

                <div className="industries-main-content d-flex justify-content-around flex-column pt-70 mx-5">
                    <h2 className="mx-auto text-center">Industries we innovate</h2>
                    <div className="row d-flex flex-column mt-md-5">

                        <div className="row d-flex flex-md-row flex-column my-md-5 mt-5">
                            <div className="col col-md-6 d-flex align-contents-around">
                                <div className="img1 align-self-center">      
                                    <div>                           
                                        <img src={img1} alt="industries" />
                                    </div>
                                </div>                            
                            </div>
                            <div className="col col-md-6 my-3 my-md-0 d-flex flex-column align-self-center">
                                <h3>Energy & Utilities</h3>
                                <p>We enable energy and utility companies to tap strategic opportunities and address operational challenges through our proven and results-oriented frameworks, approaches, and solutions across the value chain.</p>
                            </div>
                        </div> 

                        <div className="row d-flex flex-md-row flex-column-reverse my-md-5 mt-5"> 
                            <div className="col col-md-6 my-3 my-md-0 d-flex flex-column align-self-center">
                                <h3>Industry & Automation</h3>
                                <p>We help you on your Industry 4.0 digital transformation journey to create a connected and intelligent enterprise.</p>
                            </div>
                            <div className="col col-md-6 d-flex align-contents-around">
                                <div className="img1 align-self-center">      
                                    <div>                           
                                        <img src={img2} alt="industries" />
                                    </div>
                                </div>                            
                            </div>
                        </div>

                        <div className="row d-flex flex-md-row flex-column my-md-5 mt-5">
                            <div className="col col-md-6 d-flex align-contents-around">
                                <div className="img1 align-self-center">      
                                    <div>                           
                                        <img src={img5} alt="industries" />
                                    </div>
                                </div>
                            </div>
                            <div className="col col-md-6 my-3 my-md-0 d-flex flex-column align-self-center">
                                <h3>Government & Public Sector</h3>
                                <p>Our tech-driven innovations help government organizations connect better with their people in a cost-effective manner. We implement smart solutions through our people, processes & technology. Our tool-agnostic, multi-faceted approach empowers leaders to make informed decisions for better usage of public resources.</p>
                            </div>
                        </div>

                        <div className="row d-flex flex-md-row flex-column-reverse my-md-5 mt-5">
                            <div className="col col-md-6 my-3 my-md-0 d-flex flex-column align-self-center">
                                <h3>Telecommunications</h3>
                                <p>We understand the industry needs of today’s rapidly changing environment. Our solutions help clients gain the agility to drive revenue growth, increase efficiencies, better manage their operations and business, all the while ensuring an improved end-user experience.</p>
                            </div>
                            <div className="col col-md-6 d-flex align-contents-around">
                                <div className="img1 align-self-center">      
                                    <div>                           
                                        <img src={img4} alt="industries" />
                                    </div>
                                </div>                            
                            </div>
                        </div>

                        <div className="row d-flex flex-md-row flex-column my-md-5 mt-5">
                            <div className="col col-md-6 d-flex align-contents-around">
                                <div className="img1 align-self-center">      
                                    <div>                           
                                        <img src={img3} alt="industries" />
                                    </div>
                                </div>
                            </div>
                            <div className="col col-md-6 my-3 my-md-0 d-flex flex-column align-self-center">
                                <h3>Fintech</h3>
                                <p>The FinTech ecosystem's emergence has led to a game-changing paradigm shift in the banking and financial sector, introducing disruptive technologies against current legacy systems. We can help you transform and adapt through a product-centric mindset, understand user expectations, and leverage technology in an agile and iterative way.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/* <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-rocket"></i>
                            </div>
                            <h3>
                                <Link to="/service-details">
                                Startup Applicationss
                                </Link>
                            </h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna adipiscing aliqua.</p>

                            <Link className="view-details-btn" to="/service-details">
                                View Details
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-laptop"></i>
                            </div>
   
                            <h3>
                                <Link to="/service-details">
                                SaaS Solutions
                                </Link>
                            </h3>

                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna adipiscing aliqua.</p>
                            
                            <Link className="view-details-btn" to="/service-details">
                                View Details
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-money"></i>
                            </div>

                            <h3>
                                <Link to="/service-details">
                                eCommerce Platforms
                                </Link>
                            </h3>

                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna adipiscing aliqua.</p>
                            
                            <Link className="view-details-btn" to="/service-details">
                                View Details
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-segmentation"></i>
                            </div>

                            <h3>
                                <Link to="/service-details">
                                Research
                                </Link>
                            </h3>

                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna adipiscing aliqua.</p>
                           
                            <Link className="view-details-btn" to="/service-details">
                                View Details
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-analytics"></i>
                            </div>

                            <h3>
                                <Link to="/service-details">
                                Analytics
                                </Link>
                            </h3>

                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna adipiscing aliqua.</p>
                            
                            <Link className="view-details-btn" to="/service-details">
                                View Details
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-settings"></i>
                            </div>

                            <h3>
                                <Link to="/service-details">
                                Technology
                                </Link>
                            </h3>

                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna adipiscing aliqua.</p>
                            
                            <Link className="view-details-btn" to="/service-details">
                                View Details
                            </Link>
                        </div>
                    </div>
                </div>
            </div> */}
        </section>
    )
}

export default IndustriesOne